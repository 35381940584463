import { Box, Button, Image, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CalendarContext } from "../../../context/CalendarContext";
import CONSTANTS from "../../../configs/constants";
import {
  IAddCalendarOperation,
  IUpdateOperation,
} from "../../../types/calendarTypes";
import TipsImage from "../../../assets/tips.svg";
import formatNewCalendars from "../../../services/formatNewCalendar";
import { getApiUrl } from "../../../services/commonServices";

const CalendarAction = () => {
  const {
    calendarEdited,
    setCalendarEdited,
    existingCalendars,
    newCalendars,
    setNewCalendars,
    calendarSaved,
    setCalendarSaved,
    deletedCalendars,
    setDeletedCalendars,
    enabledCalendars,
    setEnabledCalendars,
    disabledCalendars,
    setDisabledCalendars,
  } = useContext(CalendarContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleCalendarSaveSuccess = () => {
    if (sessionStorage.getItem(CONSTANTS.KEYS.SOURCE) === "teams") {
      toast({
        title: "Changes saved",
        description:
          "You may close this window and return to the Tweelin app. Refresh if you don’t see your changes.",
        status: "success",
        duration: 30000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Changes saved",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    setCalendarEdited(false);
    setCalendarSaved(!calendarSaved);
    setNewCalendars([]);
    setDeletedCalendars([]);
    setEnabledCalendars([]);
    setDisabledCalendars([]);
    sessionStorage.removeItem(CONSTANTS.KEYS.NEW_CALENDARS);

    // if (sessionStorage.getItem(CONSTANTS.KEYS.SOURCE) === "teams") {
    //   onOpen();
    // }
  };

  const handleUnauthorizedAccess = () => {
    sessionStorage.clear();
    navigate("/unauthorized");
    toast({
      title: "Unauthorized Access",
      description:
        "Your access is invalid or expired. Please reopen this page from your app to continue",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleSaveFailure = () => {
    toast({
      title: "Failed to save",
      description:
        "Something went wrong while saving your calendars. Pease try again",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const saveNewCalendars = () => {
    setLoading(true);
    let newlyAddedCalendarOperations: Array<IAddCalendarOperation> = [];
    let deletedCalendarOperations: Array<IUpdateOperation> = [];
    let enabledCalendarOperations: Array<IUpdateOperation> = [];
    let disabledCalendarOperations: Array<IUpdateOperation> = [];

    if (newCalendars.length > 0) {
      const formatedNewCalendars = formatNewCalendars(newCalendars);
      newlyAddedCalendarOperations = formatedNewCalendars.map((newCalendar) => {
        return {
          operation_name: "ADD_CALENDAR",
          payload: newCalendar,
        };
      });
    }

    if (deletedCalendars.length > 0) {
      deletedCalendarOperations = deletedCalendars.map((calendar) => {
        return {
          operation_name: "DELETE_CALENDAR",
          payload: {
            calendar_id: calendar.calendar_id,
          },
        };
      });
    }

    if (enabledCalendars.length > 0) {
      enabledCalendarOperations = enabledCalendars.map((calendar) => {
        return {
          operation_name: "ENABLE_CALENDAR",
          payload: {
            calendar_id: calendar.calendar_id,
          },
        };
      });
    }

    if (disabledCalendars.length > 0) {
      disabledCalendarOperations = disabledCalendars.map((calendar) => {
        return {
          operation_name: "DISABLE_CALENDAR",
          payload: {
            calendar_id: calendar.calendar_id,
          },
        };
      });
    }

    const requestBody = {
      operations: [
        ...newlyAddedCalendarOperations,
        ...deletedCalendarOperations,
        ...enabledCalendarOperations,
        ...disabledCalendarOperations,
      ],
    };

    console.log(requestBody);

    const tokenValue = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    const apiUrl = getApiUrl(CONSTANTS.TWEELIN_API.CALENDAR);

    axios
      .post(apiUrl, JSON.stringify(requestBody), {
        headers,
      })
      .then((response) => {
        console.log(response);

        const apiStatusSuccess =
          response.status === CONSTANTS.API_RESPONSE.SUCCESS;

        if (!apiStatusSuccess) {
          throw new Error("Error in API");
        }

        const successResponse = response.data.data?.some(
          (item: any) => item.statusCode === 200,
        );

        if (successResponse) {
          handleCalendarSaveSuccess();
          return;
        }

        throw new Error("Could not save the changes");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === CONSTANTS.API_RESPONSE.UNAUTHORIZED) {
          handleUnauthorizedAccess();
          return;
        }
        handleSaveFailure();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (calendarEdited || newCalendars.length > 0) {
      setButtonDisabled(true);
      toast.closeAll();
    } else {
      setButtonDisabled(false);
    }
  }, [calendarEdited, newCalendars]);

  useEffect(() => {
    if (
      existingCalendars?.length > 0 ||
      newCalendars.length > 0 ||
      deletedCalendars.length > 0
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [existingCalendars, newCalendars, deletedCalendars]);

  return showButton ? (
    <Box mt="8">
      {buttonDisabled && (
        <Box display="flex" alignItems="flex-start" gap={2}>
          <Image
            boxSize="20px"
            objectFit="cover"
            src={TipsImage}
            alt="Tips"
            opacity="0.4"
          />
          <Text fontSize="xs" color="gray.500" marginBottom={1}>
            You have unsaved changes. Click save to sync your changes with
            Tweelin.
          </Text>
        </Box>
      )}
      <Button
        colorScheme="brand"
        size="lg"
        w="100%"
        mt={2}
        mb={8}
        isDisabled={!buttonDisabled}
        onClick={saveNewCalendars}
        isLoading={loading}
      >
        Save
      </Button>
      <Text fontSize="xs" color="gray.500" marginBottom={1} textAlign="center">
        © 2023 Tweelin Inc. All rights reserved ·{" "}
        <Link to="/disclosure">Disclosure</Link>
      </Text>
    </Box>
  ) : (
    <Box />
  );
};

export default CalendarAction;
