import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from "@chakra-ui/react";
import { IDeleteAlertModal } from "../types/uiTypes";
import CONSTANTS from "../configs/constants";

interface IDeleteAlertProps {
  alert: IDeleteAlertModal;
  cancelRef: any;
  removeCalendar: any;
  onClose: any;
}

const DeleteAlert: React.FC<IDeleteAlertProps> = ({
  alert,
  cancelRef,
  removeCalendar,
  onClose,
}) => {
  return (
    <AlertDialog
      isOpen={alert.visibility}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size={{ base: "xs", lg: "md" }}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Are you sure you want to remove{" "}
            <Text color={CONSTANTS.PRIMARY_COLOR} as="b" display="inline-block">
              &quot;{alert.calendar?.calendar_data.name}&quot;
            </Text>{" "}
            from the list?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>You will need to re-authenticate to bring it back.</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => removeCalendar()}
              variant="solid"
            >
              Remove Calendar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteAlert;
