import { createContext, useEffect, useMemo, useState } from "react";
import { ICalendar } from "../types/calendarTypes";
import {
  CalendarContextProps,
  IInitialCalendarState,
} from "../types/contextTypes";
import CONSTANTS from "../configs/constants";

const initialState: IInitialCalendarState = {
  savedExistingCalendars: [],
  setSavedExistingCalendars: () => {},
  existingCalendars: [],
  setExistingCalendars: () => {},
  newCalendars: [],
  setNewCalendars: () => {},
  deletedCalendars: [],
  setDeletedCalendars: () => {},
  enabledCalendars: [],
  setEnabledCalendars: () => {},
  disabledCalendars: [],
  setDisabledCalendars: () => {},
  calendarEdited: false,
  setCalendarEdited: () => {},
  calendarSaved: false,
  setCalendarSaved: () => {},
};

export const CalendarContext = createContext(initialState);

export const CalendarContextProvider = ({ children }: CalendarContextProps) => {
  const [savedExistingCalendars, setSavedExistingCalendars] = useState<
    ICalendar[]
  >([]);
  const [existingCalendars, setExistingCalendars] = useState<ICalendar[]>([]);
  const [newCalendars, setNewCalendars] = useState([]);
  const [deletedCalendars, setDeletedCalendars] = useState([]);
  const [enabledCalendars, setEnabledCalendars] = useState([]);
  const [disabledCalendars, setDisabledCalendars] = useState([]);
  const [calendarEdited, setCalendarEdited] = useState<boolean>(false);
  const [calendarSaved, setCalendarSaved] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      savedExistingCalendars,
      setSavedExistingCalendars,
      existingCalendars,
      setExistingCalendars,
      newCalendars,
      setNewCalendars,
      deletedCalendars,
      setDeletedCalendars,
      enabledCalendars,
      setEnabledCalendars,
      disabledCalendars,
      setDisabledCalendars,
      calendarEdited,
      setCalendarEdited,
      calendarSaved,
      setCalendarSaved,
    }),
    [
      savedExistingCalendars,
      setSavedExistingCalendars,
      existingCalendars,
      setExistingCalendars,
      newCalendars,
      setNewCalendars,
      deletedCalendars,
      setDeletedCalendars,
      calendarEdited,
      setCalendarEdited,
      calendarSaved,
      setCalendarSaved,
    ],
  );

  useEffect(() => {
    const savedNewCalendars = sessionStorage.getItem(
      CONSTANTS.KEYS.NEW_CALENDARS,
    );
    if (savedNewCalendars) {
      setNewCalendars(JSON.parse(savedNewCalendars));
    }
  }, []);

  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
};
