import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import CONSTANTS from "../configs/constants";
import TipsImage from "../assets/tips.svg";
import { ICheckBoxModal } from "../types/uiTypes";
import { CalendarContext } from "../context/CalendarContext";
import { getApiUrl } from "../services/commonServices";

interface ICheckBoxModalProps {
  modal: ICheckBoxModal;
  onCheckBoxModalClose: any;
  removeCalendar: any;
}

const CheckBoxModal: React.FC<ICheckBoxModalProps> = ({
  modal,
  onCheckBoxModalClose,
  removeCalendar,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { calendarSaved, setCalendarSaved } = useContext(CalendarContext);

  const handleCalendarSaveSuccess = () => {
    onCheckBoxModalClose();
    setCalendarSaved(!calendarSaved);
    toast({
      title: "Saved",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleCalendarSaveFailure = () => {
    toast({
      title: "Failed to save",
      description:
        "Something went wrong while saving your calendars. Pease try again",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const resubscribe = () => {
    setLoading(true);

    const requestBody = {
      operations: [
        {
          operation_name: "ENABLE_CALENDAR",
          payload: {
            calendar_id: modal.calendar?.calendar_id,
          },
        },
      ],
    };

    const tokenValue = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    const headers = {
      "Content-Type": "application/json",
      Authorization: tokenValue,
    };

    axios
      .post(
        getApiUrl(CONSTANTS.TWEELIN_API.CALENDAR),
        JSON.stringify(requestBody),
        {
          headers,
        },
      )
      .then((response) => {
        console.log(response);
        if (
          response.status === CONSTANTS.API_RESPONSE.SUCCESS &&
          response.data.data[0].statusCode === CONSTANTS.API_RESPONSE.SUCCESS
        ) {
          handleCalendarSaveSuccess();
          return;
        }

        throw new Error("Could not save the changes");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === CONSTANTS.API_RESPONSE.UNAUTHORIZED) {
          handleCalendarSaveFailure();
          return;
        }
        handleCalendarSaveFailure();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      isOpen={modal.visibility}
      onClose={onCheckBoxModalClose}
      size={{ base: "xs", lg: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color={CONSTANTS.PRIMARY_COLOR} as="b" display="inline-block">
            &quot;{modal.calendar?.calendar_data.name}&quot;
          </Text>{" "}
          need action
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          It looks like this calendar can&apos;t be synced. Please, remove it
          from the list or try resubscribing it.
          <Box display="flex" alignItems="flex-start" gap={2} mt={4}>
            <Image
              boxSize="20px"
              objectFit="cover"
              src={TipsImage}
              alt="Tips"
            />
            <Text fontSize="xs" opacity={0.7}>
              Birthday and holiday calendars can&apos;t be subscribed to. If you
              don&apos;t need them, feel free to remove.
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            variant="ghost"
            mr={3}
            onClick={() => removeCalendar(modal.calendar)}
            isDisabled={loading}
          >
            Remove Calendar
          </Button>
          <Button
            colorScheme="brand"
            onClick={() => resubscribe()}
            isLoading={loading}
          >
            Resubscribe
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckBoxModal;
