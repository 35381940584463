import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../configs/constants";

interface IProtectedProps {
  Component: React.ComponentType<any>;
}

const Protected = (props: IProtectedProps) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = sessionStorage.getItem(CONSTANTS.KEYS.USER_ID_TOKEN);
    if (!currentUser) {
      navigate("/unauthorized");
    }
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
