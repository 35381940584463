import CONSTANTS from "../configs/constants";
import { INewCalendar } from "../types/calendarTypes";

export const saveNewCalendars = (calendars: INewCalendar[]) => {
  sessionStorage.setItem(
    CONSTANTS.KEYS.NEW_CALENDARS,
    JSON.stringify(calendars),
  );
};

export const saveExistingCalendars = (calendars: INewCalendar[]) => {
  sessionStorage.setItem(
    CONSTANTS.KEYS.NEW_CALENDARS,
    JSON.stringify(calendars),
  );
};

export const handlePreference = (property: string, value: boolean) => {
  const storedPreference = sessionStorage.getItem(CONSTANTS.KEYS.PREFERENCE);

  if (storedPreference) {
    const currentPreference = JSON.parse(storedPreference);
    const updatedPreference = {
      ...currentPreference,
      [property]: value,
    };
    sessionStorage.setItem(
      CONSTANTS.KEYS.PREFERENCE,
      JSON.stringify(updatedPreference),
    );
  } else {
    const updatedPreference = {
      [property]: value,
    };

    sessionStorage.setItem(
      CONSTANTS.KEYS.PREFERENCE,
      JSON.stringify(updatedPreference),
    );
  }
};

export const getApiBase = () => {
  const source = sessionStorage.getItem(CONSTANTS.KEYS.SOURCE);
  const environment = process.env.NODE_ENV;
  let baseString = CONSTANTS.TWEELIN_API.PRODUCTION;

  if (source === "teams") {
    if (environment === "development") {
      baseString = CONSTANTS.TWEELIN_API.TEAMS_STAGING;
    } else if (environment === "production") {
      baseString = CONSTANTS.TWEELIN_API.TEAMS_PRODUCTION;
    }
  } else if (environment === "development") {
    baseString = CONSTANTS.TWEELIN_API.STAGING;
  } else if (environment === "production") {
    baseString = CONSTANTS.TWEELIN_API.PRODUCTION;
  }

  return baseString;
};

export const getApiUrl = (endPoint: string) => {
  let apiUrl = `${getApiBase()}${endPoint}`;

  if (sessionStorage.getItem(CONSTANTS.KEYS.SOURCE) === "teams") {
    const userEmail = sessionStorage.getItem(CONSTANTS.KEYS.USER_EMAIL);
    apiUrl += `?account=${userEmail}`;
  }

  return apiUrl;
};
